/* Chat.css */
.chat-container {
  background-color: #333;
  color: white;
  padding: 20px;
  max-width: auto;
  /* margin: 100px; */
  /* margin-top: 10px; */
  height: 90vh;
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.message-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 100%;
  justify-content: flex-start;
  border-radius: 10px;
  font-size: large;
  display: flex;
}

.received-conetent {
  display: block;
}

.received-text {
  margin: 10px;
}

.received {
  color: black;
  background-color: #f2f6ff;
  height: fit-content;
  width: fit-content;
}

.comment-box {
  display: flex;
}

.image {
  height: 10px;
  width: auto;
}

.comment-point {
  color: white;
  background-color: #e3698c;
  height: fit-content;
  min-width: fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
}

.sent {
  background-color: #444;
  justify-content: flex-end;
  height: fit-content;
  width: fit-content;
  text-align: right;
  margin-left: auto;
}

.input-area {
  display: flex;
  margin-top: 10px;
}

.input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
}

.button:focus,
.input:focus {
  outline: none;
}

.loading {
  color: #aaa;
  text-align: center;
}
